<template>
	<div>
		<div class="banner">
			<el-carousel height="823px">
				<el-carousel-item v-for="item in imgList" :key="item.index"><img :src="photoURL + `${item.image}`" @click="tolink(item.link)" /></el-carousel-item>
			</el-carousel>
		</div>
		<!-- 推荐专区 -->
		<div class="all">
			<div class="box">
				<div class="title">
					<h1 style="float: left">推荐专区</h1>
					<!-- <div style="float: right" class="titleRight">
						<h2>查看更多</h2>
						<img class="arrowImg" src="../img/arrow.png" />
					</div> -->
				</div>
				<div class="recommended">
					<!--  -->
					<div v-for="(item, i) in recommendedList" :key="i" @click="tolink(item.link)">
						<div class="recomoutside" v-if="i < 4">
							<div class="recomBlock">
								<img class="img" :src="photoURL + item.image" mode="aspectFit" />
								<div class="Recomword1">{{ item.title }}</div>
								<div class="Recomword2">{{ item.description }}</div>
								<div class="rate"><el-rate v-model="value2" :colors="colors"></el-rate></div>
							</div>
						</div>
					</div>
				</div>
				<!-- 热销设备 -->
				<div class="title">
					<h1 style="float: left">热租设备</h1>
					<div style="float: right" class="titleRight" @click="toList">
						<h2>查看更多</h2>
						<img class="arrowImg" src="../img/arrow.png" />
					</div>
				</div>
				<div class="recommended">
					<!--  -->
					<div v-for="(item, i) in rexiaoList" :key="i" @click="toexpertDetail(item.id)">
						<div class="recomoutside">
							<div class="recomBlock">
								<img class="img" :src="photoURL + item.rentImg" mode="aspectFit" />
								<div class="Recomword1">{{ item.name }}</div>
								<div class="Recomword2">{{ item.prodDesc }}</div>
								<div class="rate"><el-rate v-model="value2" :colors="colors"></el-rate></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			value2: 5,
			colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
			imgList: [],
			rexiaoList: [],
			recommendedList: []
		};
	},
	methods: {
		// 获取banner
		getbannerList() {
			this.$get('/shop-api/mall/banner/list', {
				os: 1,
				type: 'EQP_LEASE_BANNER'
			}).then(data => {
				if (data.code === 1000) {
					this.imgList = data.data;
				}
			});
			//推荐
			this.$get('/shop-api/mall/banner/list?os=1&type=EQP_LEASE_RECOMMEND').then(data => {
				this.recommendedList = data.data;
			});
			this.$get('/shop-api/mall/rent/hot-list?current=1').then(data => {
				this.rexiaoList = data.data.records;
			});
		},
		toexpertDetail(id) {
			this.$router.push({
				path: '/leaseDetails',
				query: { id, num: '1' }
			});
		},
		toList() {
			this.$router.push({
				name: 'leaseList'
			});
		}
	},
	mounted() {
		this.getbannerList();
	}
};
</script>

<style lang="scss" scoped>
.carousel {
}
.banner {
	background-color: #f5f5f5;
	margin: 0 auto;
	img {
		height: 823px;
		width: 100%;
	}
}
.all {
	background-color: #f5f5f5;
	.box {
		padding: 30px 0;
		margin: 0 auto;
		width: 1440px;
		.title {
			margin-top: 25px;
			height: 33px;
			line-height: 33px;
			color: #333333;
			padding: 0 15px;
			.titleRight {
				cursor: pointer;
				display: flex;
				align-items: center;
				.arrowImg {
					width: 22px;
					margin-left: 5px;
				}
			}
		}
		.recommended {
			cursor: pointer;
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			.recomoutside {
				display: flex;
				justify-content: center;
				align-items: center;
				.recomBlock {
					border-radius: 5px;
					background-color: white;
					width: 340px;
					margin: 10px;
					padding: 10px;
					text-align: center;
					.img {
						width: 300px;
						height: 300px;
						margin: 10px auto;
						background-color: #f5f5f5;
					}
					.Recomword1 {
						width: 100%;
						font-size: 18px;
						margin: 12px 0;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.Recomword2 {
						width: 100%;
						color: #888888;
						font-size: 14px;
						margin: 12px 0;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.rate {
						margin: 12px 0;
					}
					&:hover {
					  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
					}
				}
			}
		}
		.speak {
			width: 1500px;
			margin: 0 auto;
			padding-bottom: 20px;
			display: flex;
			justify-content: space-around;
			.speakLeft {
				width: 720px;
				background-color: white;
				border: 1px solid #d1d1d6;
				.speakBlock {
					width: 550px;
					margin: 20px auto;
					.img {
						width: 200px;
						height: 200px;
						border: 1px dotted #d1d1d6;
					}
					.position {
						// font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #333333;
						line-height: 28px;
						margin: 10px 0px;
					}
					.companyName {
						font-size: 14px;
						font-weight: 400;
						color: #888888;
						margin-bottom: 10px;
					}
					.intro {
						font-size: 14px;
						// font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #a4a9b0;
						line-height: 24px;
					}
				}
			}
			.speakLeft:hover {
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
				cursor: pointer;
			}
		}
	}
}
.el-carousel__item h3 {
	color: #475669;
	font-size: 18px;
	opacity: 0.75;
	line-height: 300px;
	margin: 0;
}

.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
	background-color: #d3dce6;
}
</style>
